import React from "react"
import { connect, useSelector } from "react-redux"
import { BullionQuery } from "../../prismic/staticQueries"

import Layout from "../../components/layout"
import ContentWithTable from "../../components/content-basic/content-with-table"

const Bullion = () => {
  const language = useSelector(state => state.language)
  const bullionData = BullionQuery(language)

  return (
    <Layout>
      <ContentWithTable {...bullionData} />
    </Layout>
  )
}

const mapStateToProps = state => ({ language: state.language })

export default connect(mapStateToProps)(Bullion)
